import axios from "axios";

// axios.defaults.headers["X-CSRF-TOKEN"] = window.csrf;

class Api {
    base_url = "";
    headers = {};
    protocol = window.location.protocol;
    API_URL = "edigitalnepal.edu.np/api";
    // API_URL = "davnepal.edu.np/api";
    // API_URL = "mmihs.soshgsbharatpur.edu.np/api";
    // API_URL = "whitehouseworldschool.edu.np/api";
    // API_URL = "elitegrandschool.edu.np/api";
    // API_URL = "gds.edu.np/api";
    // API_URL = "nobel.edu.np/api";
    // API_URL = "rajendraphuyal.com.np/api";
    // API_URL = "kathmandueuroschool.edu.np/api";
    // API_URL = "ankuranschool.edu.np/api";
    // API_URL = "school.bluebird.edu.np/api";
    // API_URL = "college.bluebird.edu.np/api";
    // API_URL = "bluebird.edu.np/api";
    // API_URL = "millsberryclz.edigitalnepal.edu.np/api";
    // API_URL = "millsberry.edigitalnepal.edu.np/api";
    // API_URL = "schooladarsha.edu.np/api";
    // API_URL = "patannist.edu.np/api";
    // API_URL = "elixir.edu.np/api";P
    // API_URL = "bsamv.edu.np/api";
    // API_URL = "smsv.edu.np/api";
    // API_URL = "samt.edu.np/api";
    // API_URL = "samtdharan.edu.np/api";
    // API_URL = "sams.edu.np/api";
    // API_URL = "rsmv.edu.np/api";
    // API_URL = "samc.edu.np/api";
    // API_URL = "samdharan.edu.np/api";
    // API_URL = "sampokhara.edu.np/api";
    // API_URL = "bgmsm.smsv.edu.np/api";
    // API_URL = "srv.edu.np/api";
    // API_URL = "grs.edu.np/api";
    // API_URL = "eastpoleschool.edu.np/api";
    // API_URL = "omegaschool.edu.np/api";
    // API_URL = "omegacollege.edu.np/api";
    // API_URL = "prarambhaacademy.edu.np/api";
    // API_URL = "soshgssurkhet.edu.np/api";
    // API_URL = "soshgsbharatpur.edu.np/api";
    // API_URL = "soshgsitahari.edu.np/api";
    // API_URL = "soshgssanothimi.edu.np/api";
    // API_URL = "soshgsgandaki.edu.np/api";
    // API_URL = "soshgskavre.edu.np/api";
    // API_URL = "soshgspokhara.edu.np/api";
    // API_URL = "hemsschool.edu.np/api";
    // API_URL = "venus.edu.np/api";
    // API_URL = "shwettara.edigitalnepal.edu.np/api";
    // API_URL = "ekhatanepal.com/api";
    // API_URL = "demo3.edigitalnepal.edu.np/api";
    // API_URL = "davnepal.edigitalnepal.edu.np/api";
    // API_URL = "davnepal.edu.np/api";
    // API_URL = "website.davnepal.edu.np/api";
    // API_URL = "mrjlschool.edu.np/api";
    // API_URL = "bktnist.edu.np/api";
    // API_URL = "bktnist.edigitalnepal.edu.np/api";
    // API_URL = "davnepal.edigitalnepal.edu.np/api";
    // API_URL = "45.115.218.25/api";
    // API_URL = "sxc.edu.np/api";
    // API_URL = "xavier.edigitalnepal.edu.np/api";
    // API_URL = "localhost:8000/api";
    // hostname = window.location.hostname;
    hostname =
        (window.location.hostname.indexOf("www.") && window.location.hostname) ||
        window.location.hostname.replace("www.", "");
    // API_URL = this.hostname + "/api";
    // hostname = "127.0.0.1";
    // hostname = "sgss.edu.np";
    // hostname = "edigitalnepal.edu.np";
    // hostname = "mmihs.soshgsbharatpur.edu.np";
    // hostname = "pentagon.edu.np";
    // hostname = "whitehouse.edu.np";
    // hostname = "whitehouseworldschool.edu.np";
    // hostname = "mgentertainment.com.np";
    // hostname = "smartschool.edigitalnepal.edu.np";
    // hostname = "davnepal.edu.np";
    // hostname = "elitegrandschool.edu.np";
    // hostname = "gds.edu.np";
    // hostname = "schooladarsha.edu.np";
    // hostname = "rajendraphuyal.com.np";
    // hostname = "prarambhaacademy.edu.np";
    // hostname = "ankuranschool.edu.np";
    // hostname = "nobel.edu.np";
    // hostname = "school.bluebird.edu.np";
    // hostname = "college.bluebird.edu.np";
    // hostname = "bluebird.edu.np";
    // hostname = "millsberryclz.edigitalnepal.edu.np";
    // hostname = "millsberry.edigitalnepal.edu.np";
    // hostname = "patannist.edu.np";
    // hostname = "elixir.edu.np";
    // hostname = "omegaschool.edu.np";
    // hostname = "omegacollege.edu.np";
    // hostname = "kathmandueuroschool.edu.np";
    // hostname = "soshgssurkhet.edu.np";
    // hostname = "soshgsbharatpur.edu.np";
    // hostname = "soshgsitahari.edu.np";
    // hostname = "soshgsgandaki.edu.np";
    // hostname = "soshgssanothimi.edu.np";
    // hostname = "soshgskavre.edu.np";
    // hostname = "soshgspokhara.edu.np";
    // hostname = "samt.edu.np";
    // hostname = "rsmv.edu.np";
    // hostname = "samc.edu.np";
    // hostname = "bsamv.edu.np";
    // hostname = "smsv.edu.np";
    // hostname = "sams.edu.np";
    // hostname = "samdharan.edu.np";
    // hostname = "sampokhara.edu.np";
    // hostname = "bgmsm.smsv.edu.np";
    // hostname = "srv.edu.np";
    // hostname = "prarambhaacademy.bktnist.edu.np";
    // hostname = "hemsschool.edu.np";
    // hostname = "venus.edu.np";
    // hostname = "shwettara.edigitalnepal.edu.np";
    // hostname = "davnepal.edigitalnepal.edu.np";
    // hostname = "davnepal.edu.np";
    // hostname = "website.davnepal.edu.np";
    // hostname = "mrjlschool.edu.np";
    // hostname = "bktnist.edu.np";
    // hostname = "45.115.218.25";
    // hostname = "sxc.edu.np";
    // hostname = "ekhatanepal.com";
    // hostname = "bktnist.edigitalnepal.edu.np";
    // hostname = "davnepal.edigitalnepal.edu.np";
    // hostname = "xavier.edigitalnepal.edu.np";
    // hostname = "sxc.edigitalnepal.edu.np";
    // hostname = "demo3.edigitalnepal.edu.np";
    // hostname = "demo2.edigitalnepal.edu.np";
    // hostname = "camadcollege.edu.np";
    // hostname = "sgss.edu.np";
    // hostname = "nawajagritischool.edu.np";
    // hostname = "glaciercollege.edu.np";
    // hostname = "milestonecollege.edu.np";
    // hostname = "pentagon.edu.np";
    // hostname = "cao.p2.gov.np";
    // hostname = "eastpoleschool.edu.np";
    // hostname = "lfs.edu.np";
    // hostname = "grs.edu.np";
    // hostname = "spspokhara.edu.np";
    // hostname = "website.edigitalnepal.com.np";
    // hostname = "uniquecoms.com";
    // hostname = "demo.edigitalnepal.edu.np";
    // hostname = "demo.edigitalnepal.com.np";
    // hostname = "demo.edigitalnepal.edu.np";
    // hostname = "mgentertainment.com.np";
    // hostname = "super@admin.com";
    // hostname = "bnks.edu.np";
    // hostname = "demo.edigitalnepal.edu.np";

    constructor() {
        // this.headers["Authorization"] = `Bearer ${
        //   process.env.REACT_APP_SCHOOL_AUTHORIZATION_TOKEN
        // }`;
        this.base_url = this.protocol + "//" + this.API_URL;
    }

    get(path, data) {
        const url = `${this.base_url}${path}`;
        return axios
            .get(url, {params: {client: this.hostname, data: data}})
            .then(function (response) {
                // console.log(response);
                return response.data;
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    post(path, data) {
        const url = `${this.base_url}${path}`;
        this.headers["Content-Type"] =
            "application/x-www-form-urlencoded;charset=UTF-8";

        return axios
            .post(url, {
                headers: this.headers,
                client: this.hostname,
                body: data,
            })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    create(path, data) {
        const url = `${this.base_url}${path}`;
        this.headers["Content-Type"] =
            "application/x-www-form-urlencoded;charset=UTF-8";

        return axios
            .post(url, {
                headers: this.headers,
                client: this.hostname,
                body: data,
            })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
}

export default Api;
