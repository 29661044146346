import React from "react";

// react-jss
import injectSheet from "react-jss";

// asset import
import "./index.css";

// custom styles
const styles = {
    pageHeader: {
        height: props => (props.height ? props.height : "60vh"),
        backgroundImage: props => `url(${props.image})`,
        backgroundSize: "cover",
        backgroundPosition:"center",
        backgroundRepeat: "no-repeat",
    }
};

const PageHeader = (props) => {
    const { classes } = props;
    return(
        <div className={classes.pageHeader + " with-overlay"} />
    )
};

export default injectSheet(styles)(PageHeader);