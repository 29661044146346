import React from "react";
import {connect} from "react-redux";

//action import
import {getHomePageData} from "../../actions/frontAction";

//component import
import Loader from "components/loader/Loader";
// import PopupModal from 'components/modals/index';
import RenderTheme from "../../utils/RenderTheme";

class Sandip extends React.Component {
    render() {
        const {homePage, layout, websiteData} = this.props;
        const popup = websiteData.popup;

        const defaultBlockProps = {
            ...websiteData,
            profile: layout.profile,
            socialMedia: layout.socialMedia,
            menu: layout.menu,
            theme: layout.theme,
            usefulLink: layout.usefulLink,
        };
        console.log(this.props)
        return (
            <>
                {

                    homePage.isLoaded ?
                        (
                            <React.Suspense fallback="">
                                <RenderTheme themeId={"Footer12"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavHeader"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavSlider"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavCourse2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"NoticeEventContentBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavNews"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavAward"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavCounter"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavTestimonial"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavUsefullink2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavGallery"} {...defaultBlockProps} />
                                <RenderTheme themeId={"DavFooter"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CourseBlock8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Faq"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ContentBlock6"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CourseBlock7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"NewsSection5"} {...defaultBlockProps} />
                                <RenderTheme themeId={"EventSection8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"rpsharmaHeaderBg"} {...defaultBlockProps} />
                                <RenderTheme themeId={"RpsharmaSlider"} {...defaultBlockProps} />
                                <RenderTheme themeId={"RpsharmaAbout"} {...defaultBlockProps} />
                                <RenderTheme themeId={"RpsharmaPortfolio"} {...defaultBlockProps} />
                                <RenderTheme themeId={"RpsharmaSkills"} {...defaultBlockProps} />
                                <RenderTheme themeId={"RpsharmaTestimonial"} {...defaultBlockProps} />
                                <RenderTheme themeId={"RpsharmaBlog"} {...defaultBlockProps} />
                                <RenderTheme themeId={"RpsharmaFooter"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Header12"} {...defaultBlockProps} />
                                <RenderTheme themeId={"InstaPage"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzBlogFbYoutubeBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzMobileMenu"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzNoticeEventsBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzTestimonial2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzTestimonial3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction26"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzBlog"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzHeader"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzSlider2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzSlider"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzAbout"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Notice4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzEvent"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzNews"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzApplyBanner"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzCourse"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzVideoBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzTestimonial"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryClzFooter"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryHeader"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryHeader2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberrySlider"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberrySlider2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryApplyBanner"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsBerryAbout"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsBerryCourse"} {...defaultBlockProps} />
                                <RenderTheme themeId={"NewsEventContentHolder"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsBerryTestimonial"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryGlobeMap"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsBerryBlog"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryFacilities"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryCounter"} {...defaultBlockProps} />
                                <RenderTheme themeId={"MillsberryFooter"} {...defaultBlockProps} />
                                <RenderTheme themeId={"SimpleBackground"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ApplyButton"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction9"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction10"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction11"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction12"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction13"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction14"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction15"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction16"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction17"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction18"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction19"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction20"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction21"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction22"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction23"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction24"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction25"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction26"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction27"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction29"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Introduction30"} {...defaultBlockProps} />
                                <RenderTheme themeId={"NewsSlider1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ContentBlock2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ContentBlock3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ContentBlock4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ContentBlock5"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ContentBlock6"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HeaderContentBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Header4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Header7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Header8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Header9"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Header10"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Header11"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider5"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider6"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider9"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider10"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomeSlider11"} {...defaultBlockProps} />
                                <RenderTheme themeId={"HomePage"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Program2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CourseBlock3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CourseBlock4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CourseBlock6"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CareerVacancies2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CareerVacancies3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"OurPartners"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Message"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Message2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Download1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CardEffect"} {...defaultBlockProps} />
                                <RenderTheme themeId={"VideoBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"GalleryBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"GalleryBlock2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock5"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock6"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock9"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock10"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock11"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock12"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock13"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock14"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock15"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock16"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock17"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock18"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock19"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock20"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock21"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock22"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock23"} {...defaultBlockProps} />
                                <RenderTheme themeId={"ServiceBlock24"} {...defaultBlockProps} />
                                <RenderTheme themeId={"EventSection4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"EventSection7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TeamComponent"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TeamComponent2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TeamComponent3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TestimonialSlider3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TestimonialSlider4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TestimonialSlider5"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TestimonialSlider6"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TestimonialSlider7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TestimonialSlider8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"TestimonialSlider9"} {...defaultBlockProps} />
                                <RenderTheme themeId={"SubscribeBlock1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Blog1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Blog3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Blog4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"SocialBlock1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"SocialBlock2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CounterComponent"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CounterComponent2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CounterComponent3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CounterComponent4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CounterComponent5"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Contact2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Contact3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Contact4"} {...defaultBlockProps} />
                                <RenderTheme themeId={"CardBlock1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Portpholio1"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer2"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer3"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer5"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer6"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer7"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer8"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer9"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer10"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer11"} {...defaultBlockProps} />
                                <RenderTheme themeId={"Footer12"} {...defaultBlockProps} />
                                <RenderTheme themeId={"SxcWebsite"} {...defaultBlockProps} />
                                {/*<RenderTheme themeId={"PopupModal1"} {...defaultBlockProps} />*/}
                                {/* commented component  */}
                                {/* <RenderTheme themeId={"Loader16"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"SxcWebsite"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"AboutBlock1"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"AboutBlock2"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Introduction"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"DownloadOne"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Loader17"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Loader18"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"HomePage2"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Introduction2"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Introduction3"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Introduction4"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Introduction5"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"Introduction6"} {...defaultBlockProps} /> */}
                                {/* <RenderTheme themeId={"SimpleBgComponent"} {...defaultBlockProps} /> */}
                            </React.Suspense>
                        ) :
                        <div className="async-block" style={{minHeight: "0vh"}}><Loader/></div>
                }

                {/* {popup.length ?
                    popup.map((popup, i) => {
                        if (popup.data && popup.data.length) {
                            return popup.data.map((item, j) => {
                                return <PopupModal key={i + '_' + j} resource_path={popup.resource_path} itemData={item} />;
                            });
                        }
                    })
                    : null} */}
            </>
        );
    }

    componentDidMount() {
        this.props.getHomePageData();
    }
}

function mapStateToProps(state) {
    return {
        layout: state.layout,
        homePage: state.homePage,
        websiteData: state.websiteData
    };
}

export default connect(mapStateToProps, {getHomePageData})(Sandip);